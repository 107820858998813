<template>
  <div style="background-color: white;margin: 10px;padding: 10px;">

    <a-row style="margin:10px 0;">
      <a-col :span="2"></a-col>
      <a-col :span="6">
        <a-statistic title="任务总数" :value="missionStatistic.total + '项'" style="margin-right: 50px" />
      </a-col>
      <a-col :span="6">
        <a-statistic title="已完成任务数" :precision="0" :value="missionStatistic.complete + '项'" />
      </a-col>
      <a-col :span="2">

      </a-col>
      <a-col :span="6">
        <a-statistic title="剩余任务" :precision="0" :value="missionStatistic.surplus + '项'" />
      </a-col>
      <a-col :span="2"></a-col>
    </a-row>

    <div style="background-color: #F0F2F5;margin-left: -10px;margin-right: -10px; padding:-10px;height: 20px;">

    </div>

    <a-row style="background-color: #F0F2F5;margin: -10px;">
      <a-space>

        <a-button type="primary" @click="showModal">
          小任务创建
        </a-button>
        <a-modal @cancel="onModalClose" :visible="modalVisible" title="小任务创建" ok-text="确认" cancel-text="取消"
          @ok="handleOk">
          <a-form>
            <a-form-item label="任务名称">
              <a-input v-model="taskName"></a-input>
            </a-form-item>
            <a-form-item label="上传地块文件">
              <div class="dropbox">
                <a-upload-dragger :file-list="fileList" :customRequest="customRequest"
                  @change="handleChange">

                  <!-- v-decorator="[
                    'dragger',
                    {
                      valuePropName: 'fileList',
                      getValueFromEvent: normFile,
                    },
                  ]" -->
                  <p class="ant-upload-drag-icon">
                    <a-icon type="inbox" />
                  </p>
                  <p class="ant-upload-text">
                    点击区域上传 / 拖拽到本区域上传
                  </p>
                </a-upload-dragger>
              </div>
            </a-form-item>
          </a-form>
        </a-modal>
      </a-space>

    </a-row>

    <div style="background-color: #F0F2F5;margin-left: -10px;margin-right: -10px; padding:-10px;height: 20px;">

    </div>
    <a-table :pagination="paginationProps" :columns="columns" :data-source="pageInfo.records" @change="tableChange">

      <span slot="customTitle">任务ID</span>

      <span slot="type" slot-scope="type">
        <span v-if="type == 'small'">
          TIF
        </span>
        <span v-else-if="type == 'img'">
          常规图片（无坐标信息）
        </span>
        <span v-else>
          大图
        </span>
      </span>
      <span slot="taskStatus" slot-scope="taskStatus">
        <span v-if="taskStatus == '1'">
          完成
        </span>
        <span v-else-if="taskStatus == '0'">
          未开始
        </span>
        <span v-else>
          进行中
        </span>
      </span>


      <span slot="tag" slot-scope="tag">
        <a-tag v-for="t in JSON.parse(tag)" :key="t" :color="t == 'gengyun' ? 'green' : 'purple'">
          {{ t.toUpperCase() }}
        </a-tag>
      </span>

      <span slot="action" slot-scope="text, record">

        <a :disabled="record.type == 'img' ? null : 'disabled'" @click="deleteMission(record.taskId)">删除</a>
        <a-divider type="vertical" />
        <a-button v-if="record.taskStatus == 0" @click="startTask(record.taskId)" type="primary" danger ghost>
          开始任务
        </a-button>
        <a-button v-else disabled>
          开始任务
        </a-button>
        <a-divider type="vertical" />

        <a-button type="dashed" @click="showDrawer(record.taskId)" class="ant-dropdown-link">详情</a-button>

      </span>

    </a-table>

    <a-drawer :title="taskInfo.taskName" width="800" :visible="visible" @close="onClose">
      <template #extra>
        <a-button style="margin-right: 8px" @click="onClose">关闭</a-button>
        <a-button type="primary" @click="onClose">确定</a-button>
      </template>



      <a-col v-if="taskInfo.type == 'img'">
        缩略图(右键打开放大)
        <img :src="taskInfo.baseUrl" height="300" width="300" alt="">
      </a-col>

      <a-row style="margin-top: 20px;" v-for="(value, key, index) in taskInfo" :key="index">
        <a-col :span="6" v-if="key == 'taskId'">
          任务Id
        </a-col>
        <a-col :span="6" v-if="key == 'taskName'">
          任务名称
        </a-col>
        <a-col :span="6" v-if="key == 'taskJson'">
          任务结果JSON
        </a-col>
        <a-col :span="6" v-if="key == 'taskTime'">
          任务创建时间
        </a-col>
        <a-col :span="6" v-if="key == 'taskResult'">
          出苗结果
        </a-col>


        <a-col v-if="key == 'taskId' || key == 'taskName' || key == 'taskJson' || key == 'taskTime' || key == 'taskResult'"
          :span="18">
          {{ value }}
        </a-col>
      </a-row>

    </a-drawer>



  </div>
</template>
  
<script>
import COS from 'cos-js-sdk-v5';
import axios from "axios";

export default ({
  data() {
    return {
      uploadProps: {},
      cosUrl: "",
      taskName: "",
      modalVisible: false,
      fileList: [],
      taskInfo: {},//当前显示详情的任务
      visible: false,
      pageInfo: {
        "records": [],
        "total": 0,
        "size": 0,
        "current": 0,
        "orders": [],
        "optimizeCountSql": true,
        "searchCount": true,
        "maxLimit": null,
        "countId": null,
        "pages": 0
      },
      paginationProps: {

        pageSize: 2,
        total: 0,
        showQuickJumper: true,
      },
      missionStatistic: {
        total: 0,
        complete: 0,
        surplus: 0
      },
      columns: [
        {
          dataIndex: 'taskId',
          key: 'taskId',
          slots: { title: 'customTitle' },
          scopedSlots: { customRender: 'taskId' },
        },
        {
          title: '任务名称',
          dataIndex: 'taskName',
          key: 'taskName',
        },
        {
          title: '模型名称',
          dataIndex: 'modelName',
          key: 'modelName',
        }, {
          title: '任务类型',
          key: 'type',
          dataIndex: 'type',
          scopedSlots: { customRender: 'type' },

        },{
          title:"结果",
          key:"taskResult",
          dataIndex:"taskResult"
        },
        {
          title: '标签',
          key: 'tag',
          dataIndex: 'tag',
          scopedSlots: { customRender: 'tag' },
        },
        {
          title:'时间',
          key:'taskTime',
          dataIndex:"taskTime"
        },
        {
          title: '任务进度',
          key: "taskStatus",
          dataIndex: "taskStatus",
          scopedSlots: { customRender: 'taskStatus' },
        },
        {
          title: '操作',
          key: 'action',
          scopedSlots: { customRender: 'action' },
        },
      ]
    }
  },
  mounted() {
    this.refreshPage(1);
  },
  methods: {
    handleChange({ file, fileList }) {
      this.fileList = fileList
    },
    beforeUpload(file, fileList) {
      console.log("before upload", file, fileList)
    },
    customRequest({
      // action,
      // data,
      file,
      // filename,
      headers,
      // onError,
      onProgress,//这里作为参数传进来，让axios的回调进行使用
      onSuccess,
      withCredentials,
    }) {
      const _this = this;
      const cos = new COS({
        SecretId: 'AKIDd6IDsHQKzPZUWKs98rJUR6Udcy6xWnmX',
        SecretKey: 'Zty1xa5NSLF5g2A5qMcfU3NRIuHQQBiz',
      });
      cos.uploadFile({
        Bucket: 'scanner3d-1316598877', /* 填写自己的 bucket，必须字段 */
        Region: 'ap-nanjing',     /* 存储桶所在地域，必须字段 */
        Key: file.name,              /* 存储在桶里的对象键（例如:1.jpg，a/b/test.txt，图片.jpg）支持中文，必须字段 */
        Body: file, // 上传文件对象
        SliceSize: 1024 * 1024 * 5,     /* 触发分块上传的阈值，超过5MB使用分块上传，小于5MB使用简单上传。可自行设置，非必须 */
        onProgress: function (progressData) {
          //想办法让下面的东西控制进度条
          //自己输出，为了不破坏antd的onProgress内部构造，目前还不知道自己重写antd的onProgress如何显示进度条，那么就不改动他的了
          console.log((progressData.loaded / progressData.total * 100).toFixed(2) + "%")//打开控制台可以看到更加精确的数值，下面的那个使用的整数（这个antd要求的）
          onProgress({ percent: Math.round((progressData.loaded / progressData.total) * 100) }, file);//这个是antd给我们传入的

        }
      }, function (err, cosData) {
        if (err) {
          console.log(err);
          // onError(err)//调用外部的onError方法，让配置的antd的处理函数处理，下面同理
        } else {
          console.log(cosData)
          onSuccess(cosData, file)//手动调用onSuccess告诉antd完成上传了
          // const formData = new FormData()


          // console.log(data.taskInfo.taskId)
          // console.log(cosData.Location)
          /**cosData格式
           * {
                "statusCode": 200,
                "headers": {
                    "content-length": "0",
                    "etag": "\"a016eb8decf5a22bcc2344358878b6f0\"",
                    "x-cos-request-id": "NjRhNzY5MjBfMmE2ZWFhMDlfMTA5MTNfMmE0ZTBkMg=="
                },
                "Location": "scanner3d-1316598877.cos.ap-nanjing.myqcloud.com/wallhaven-85dwdk.jpg",
                "ETag": "\"a016eb8decf5a22bcc2344358878b6f0\"",
                "RequestId": "NjRhNzY5MjBfMmE2ZWFhMDlfMTA5MTNfMmE0ZTBkMg=="
            }
           */
          _this.cosUrl = cosData.Location;//给this的data赋值
        }
      });

      return {
        abort() {
          console.log('upload progress is aborted.');
        },
      };
    },

    normFile(e) {
      console.log('Upload event:', e);
      console.log("normFile 被调用e为", e)
      if (Array.isArray(e)) {
        return e;
      }
      return e && e.fileList;
    },

    showModal() {
      this.modalVisible = true;
    },

    startTask(taskId) {
      console.log(taskId)
      const formData = new FormData()
      formData.append("taskId", taskId)
      axios.post("http://43.143.170.49:8466/api/taskDetection/startGengyunTask", formData).then(res => {
        console.log(res)
        this.refreshPage(this.pageInfo.current)
      })
    },

    showDrawer(taskId) {
      console.log(taskId);
      this.visible = true;
      const formData = new FormData();
      formData.append('taskId', taskId);
      axios.post("http://43.143.170.49:8466/api/taskDetection/oneById", formData).then(res => {
        console.log(res)
        this.taskInfo = res.data.data
        if (this.taskInfo.type == 'img') {
          console.log("img")
          axios.get(this.taskInfo.taskJson).then(res => {
            console.log(res)
            console.log("jsos inner is ", res.data[1])
            this.taskInfo.taskResult = res.data[1]
            this.taskInfo.baseUrl = res.data[0]
          })
        }
      })

      // for(let i=0;i<this.pageInfo.records.length;i++){
      //     if( this.pageInfo.records[i].type =="img"){
      //       console.warn("类型是img，内部json是"+this.pageInfo.records[i].taskJson)


      //     }
      //   }
      //   console.log("内部数据是",this.pageInfo.records[7].inner)


    },

    onModalClose() {
      console.log("删除之前的file List", this.fileList)
      this.fileList = []
      console.log("删除之后的file List", this.fileList)

      this.modalVisible = false;
    },

    onClose() {
      this.visible = false;
    },

    handleOk(e) {
      const fd = new FormData();
      fd.append("name", this.taskName)
      fd.append("file", this.cosUrl)
      axios.post("http://43.143.170.49:8466/api/taskDetection/addSmall", fd).then(res => {
        console.log(res);
      })
      console.log(this.taskName, this.cosUrl)
      console.log(e);
      this.taskName = ""
      this.cosUrl = ""
      this.modalVisible = false;
      this.refreshPage(this.pageInfo.current);
    },

    handleMenuClick(e, id) {
      console.log('click', e.key);
      let newProgress;
      if (e.key == 'ongoing') {
        newProgress = -1;
      } else if (e.key == 'complete') {
        newProgress = 1;
      } else {
        newProgress = 0;
      }
      let formData = new FormData;
      formData.append("id", id);
      formData.append("progress", newProgress);
      axios.post("http://43.143.170.49:8466/api/taskDetection/updateProgress", formData).then(res => {
        console.log(res);
        this.refreshPage(this.pageInfo.current);

      })
    },

    deleteMission(id) {
      let formData = new FormData;
      formData.append("id", id);
      axios.post("http://43.143.170.49:8466/api/taskDetection/delete", formData).then(res => {
        console.log(res);

        this.pageInfo.current
        this.refreshPage(this.pageInfo.current);
      })

    },
    tableChange(pagination, filters, sorter, { currentDataSource }) {
      console.log(filters, sorter, currentDataSource);
      this.refreshPage(pagination.current)
    },

    //刷新页面，传入希望获取的页数，同时会刷新统计数量
    refreshPage(pageIndex) {
      axios.get("http://43.143.170.49:8466/api/taskDetection/taskPage", {
        params: {
          current: pageIndex
        }, headers: {
          Authorization: localStorage.getItem("token")
        }
      }).then(res => {
        //响应式必须手动赋值
        console.log("获取任务页面：", res)
        this.pageInfo = res.data.data
        this.paginationProps.total = this.pageInfo.total;
        this.paginationProps.pageSize = this.pageInfo.size;
      });
      axios.get("http://43.143.170.49:8466/api/taskDetection/taskStatistical", {
        headers: {
          Authorization: localStorage.getItem("token")
        }
      }).then(res => {
        console.log("统计信息为：", res.data.data)
        this.missionStatistic = res.data.data
        console.log(res);
      })
    },
  },
});

</script>
  
<style></style>